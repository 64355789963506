import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from '../utils/translation';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useImageComponent, useLinkComponent } from '../utils/component';
import type { LinkProps } from '../atoms/types';
import { displayDateDiff } from '../atoms/date/utils';
import Comment from '../icons/comment';

interface AuthorProp {
  id: string;
  title?: string;
  url?: string;
}

export interface PostItemProps {
  image: { imageId: string; alt: string };
  title: string;
  sponsored: boolean;
  date: string;
  link: LinkProps;
  authors?: AuthorProp[];
  commentsNb?: number;
  large?: boolean;
  card?: boolean;
  mainTag?: string;
  hideMobileInformations?: boolean;
}

const Title = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  font-family: var(--theme-typography-primary-font);
  font-weight: 700;
  font-size: 22px;
  line-height: 1.38;
  margin: var(--spacer) 0 var(--spacer-s);
  overflow: hidden;
  transition: color 0.3s ease;

  &.mod-large {
    font-size: 26px;
  }

  .mod-card & {
    padding: 0 var(--spacer-s);
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 19px;

      &.mod-large {
        font-size: 19px;
      }
    `,
  )}
`;

const Post = styled.article`
  color: var(--theme-colors-foreground);
  display: inline-flex;
  flex-direction: column;
  position: relative;

  a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  picture {
    width: 100%;
  }

  &:hover ${Title} {
    color: var(--theme-color-primary);
  }

  &.mod-large {
    width: 100%;
  }

  &.mod-card {
    border-radius: 10px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    min-height: 400px;

    ${mq.lte(
      DEVICE_SIZE.LARGE,
      css`
        border-radius: 6px;
      `,
    )}
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      img {
        object-fit: cover;
      }

      &.mod-card {
        min-height: initial;
      }
    `,
  )}
`;

const PublishDate = styled.span`
  color: var(--theme-colors-foreground);
`;

const Bottom = styled.div`
  align-items: center;
  display: flex;
  font-size: 13px;
  margin: auto 0 0;
  white-space: nowrap;

  .mod-card & {
    padding: 0 var(--spacer-s) var(--spacer-s);
  }

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      white-space: initial;
    `,
  )}
`;

const Informations = styled.span`
  flex: 1;

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      &.mod-hidden-mobile {
        display: none;
      }
    `,
  )}
`;

const Comments = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: var(--spacer-xs);
  margin: 0 0 0 auto;

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      position: absolute;
      right: var(--spacer-s);
      top: 175px;
    `,
  )}
`;

const Label = styled.div`
  align-self: start;
  background: rgba(0, 0, 0, 0.3);
  border: solid 2px white;
  border-radius: 3px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.62;
  margin: calc(-1 * var(--spacer-m)) var(--spacer-s) 0;
  padding: 3px 12px;
  text-transform: uppercase;
  z-index: 1;
`;

export const PostItem: any = (props: PostItemProps) => {
  const { image, title, date, link, authors, commentsNb, large, card, mainTag, sponsored, hideMobileInformations } =
    props;

  const t = useTranslation();
  const ImageComponent = useImageComponent();
  const LinkComponent = useLinkComponent();

  const imageProps = {
    ...image,
    mobileWidth: 313,
    mobileHeight: 204,
    desktopWidth: large ? 560 : 372,
    desktopHeight: large ? 301 : 200,
  };

  const locale = t('locale');
  const dateTimeAgo = displayDateDiff(date, locale);

  const classes = `${large ? 'mod-large' : ''} ${card ? 'mod-card' : ''}`;

  return (
    <Post className={classes}>
      <LinkComponent {...link}>
        <ImageComponent {...imageProps} />
      </LinkComponent>
      {mainTag || sponsored ? <Label>{sponsored ? t('design.postItem.sponsored') : mainTag}</Label> : null}
      <LinkComponent {...link}>
        <Title className={large ? 'mod-large' : ''} data-testid="meta-label">
          {title}
        </Title>
      </LinkComponent>
      <Bottom>
        <Informations className={hideMobileInformations ? 'mod-hidden-mobile' : ''}>
          <PublishDate data-testid="meta-date">{dateTimeAgo},&nbsp;</PublishDate>
          {t('design.postItem.authorLabel')}&nbsp;
          <strong>{authors}</strong>
        </Informations>
        {commentsNb ? (
          <LinkComponent {...link}>
            <Comments>
              <Comment width={24} height={24} color="var(--theme-color-primary)" />
              {commentsNb}
            </Comments>
          </LinkComponent>
        ) : null}
      </Bottom>
    </Post>
  );
};
