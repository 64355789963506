import React, { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import styled, { css } from 'styled-components';

import { BottomNavCustom } from '@marty-js/design/src/molecules/bottom-navigation-custom';
import Home from '@marty-js/design/src/icons/material-symbols/home';
import DealsIcon from '@marty-js/design/src/icons/material-symbols/deals';
import NewsIcon from '@marty-js/design/src/icons/material-symbols/news';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import { useGetGenericItemBySection } from '@marty-js/api-sdk/services/genericItemBySection';
import { CommentCard } from '@marty-js/design/src/molecules/cards/comment';
import { Slider } from '@marty-js/design/src/molecules/slider';
import { Cta } from '@marty-js/design/src/molecules/cta';
import { PostItemMix } from '@marty-js/design/src/molecules/post-item-mix';
import { Select } from '@marty-js/design/src/atoms/filters/select';
import SwitchViewMode from '@marty-js/design/src/molecules/switch-view-mode';
import type { GenericItem } from '@marty-js/api-sdk/types';
import type { GetGenericItemBySectionVariables } from '@marty-js/api-sdk/__generated__/GetGenericItemBySection';
import { Container, FullContent } from '../../../atoms/grid-container';
import { Ad } from '../../../ads/ad';
import { useSetMaxFirstImages } from '../../../utils/imageContext';
import type {
  BestComment,
  BestComments,
  HomepageFilters,
  Insert,
  InsertTabs,
  LayoutSectionProps,
  Trend,
  Trends,
} from '../types';
import { SelectedItemsLineTabs } from './partials/selected-items-line-tabs';
import { PostsList } from '../../../layout/post/posts-list';
import { postItemReducer } from '../../../utils/listing';
import { ShowcaseListing } from './partials/showcase-listing';
import { ShowcaseListingAd } from './partials/showcase-listing-ad';
import { ShowcaseAndCompactListing } from './partials/showcase-and-compact-listing';
import { NavLink } from '../../../atoms/nav-link';
import { displayDateDiff, getHistoryDate } from '../../../atoms/date/utils';

const ArrowPlainRight = dynamic(() => import('@marty-js/design/src/icons/arrowPlainRight'));

const Featured = styled.div`
  display: flex;
  gap: var(--spacer);
  margin: var(--spacer-fluid) 0 var(--spacer-m-fluid);

  ${mq.lte(
    1100,
    css`
      flex-direction: column-reverse;
    `,
  )}
`;

const Feed = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);

  ${mq.gte(
    1100,
    css`
      max-width: 360px;

      > *:nth-of-type(2) {
        max-height: 805px;
        overflow-y: auto;
        padding: 0 var(--spacer-s) 0 0;

        &::-webkit-scrollbar {
          background: var(--color-alt-3);
          border-radius: var(--spacer-xs);
          width: 12px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--background-color-alt);
          border: solid 2px var(--color-alt-3);
          border-radius: var(--spacer-xs);
        }
      }
    `,
  )}
`;

const TitleFeed = styled.div`
  ${mq.lte(
    1100,
    css`
      align-items: center;
      display: flex;
      justify-content: space-between;
    `,
  )}
`;

const CommentHeading = styled.div`
  text-align: center;
  grid-column: 1 / 10;
`;

const CommentBody = styled.div`
  margin: 0 0 var(--spacer-m-fluid);
`;

const Heros = styled.div`
  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      padding-top: 3px;
    `,
  )}
`;

const TrendsElt = styled.div`
  display: flex;
  gap: var(--spacer-s);
  margin-bottom: var(--spacer-fluid);

  ${mq.lte(
    1100,
    css`
      overflow-x: auto;
      width: 100vw;
      margin: 0 0 var(--spacer-fluid) calc(-1 * var(--spacer-fluid));
      padding: 0 var(--spacer-fluid);
    `,
  )}
`;

const TrendElt = styled.span`
  border: 1px solid var(--theme-color-medium);
  border-radius: 30px;
  cursor: pointer;
  padding: var(--spacer-xs) var(--spacer-s);
  transition: background 0.3s, color 0.3s;
  white-space: nowrap;

  &:hover {
    background-color: var(--text-color-alt);
    color: var(--theme-color-background);
  }
`;

const HeadingNews = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--spacer-s);
  justify-content: space-between;
`;

const AdContainer = styled.div`
  margin: 0 0 var(--spacer-fluid);
  width: 100%;
`;

type HomepageMainFlux = {
  genericItemList?: GenericItem[];
  button?: string;
  title?: string;
  url?: string;
  info?: any;
};

export type HomepageData = {
  mainFlux: HomepageMainFlux;
  hero: {
    heros: {
      id: string;
      url: string;
      image: string;
      title: string;
      updatedAt?: string;
      comments?: number;
      tagSlugs?: string[];
      mainTag?: string;
    }[];
  };
  insert1: Insert;
  insert2: Insert;
  insert3: Insert;
  insertTab1: InsertTabs;
  insertTab2: InsertTabs;
  insertTab3: InsertTabs;
  bestComments?: BestComments;
  trends?: Trends;
  filters?: HomepageFilters;
};

const getItemListFromComponent = (comp: any): GenericItem[] => {
  if (comp?.dataComponent?.items) {
    return comp.dataComponent.items.genericItemList;
  }

  return comp.dataComponent.genericItems.genericItemList;
};

const Homepage: React.FC<LayoutSectionProps<HomepageData>> = ({ data, pageInfo }) => {
  const t = useTranslation();
  const [genericItems, setGenericItems] = useState<GenericItem[]>(data.mainFlux?.genericItemList);
  const size = data.mainFlux?.info?.size ?? 20;
  const [filterTag, setFilterTag] = useState<string>(null);
  const [mode, setMode] = useState<string>(null);
  useSetMaxFirstImages(5);
  const variables: GetGenericItemBySectionVariables = useMemo(
    () => ({
      sectionId: pageInfo?.section?.id,
      from: 0,
      tag: filterTag,
      size,
    }),
    [pageInfo?.section?.id, size, filterTag],
  );

  const { result, loading, fetchData } = useGetGenericItemBySection({ variables });

  useEffect(() => {
    if (variables.tag !== null) {
      fetchData({ variables });
    }
  }, [variables, variables.tag, fetchData]);

  useEffect(() => {
    if (!loading) {
      if (result) {
        setGenericItems(result.genericItemBySection.genericItems);
      }
    }
  }, [setGenericItems, result, loading, data.mainFlux, pageInfo.section.url]);

  const { insertTab1, insertTab2, insertTab3 } = data;
  const { insert1, insert2, insert3 } = data;
  const mainFluxButton = {
    title: data.mainFlux.title,
    button: data.mainFlux.button,
    url: data.mainFlux.url,
  };

  const insert1items = getItemListFromComponent(insert1);
  const insert2items = getItemListFromComponent(insert2);
  const insert3items = getItemListFromComponent(insert3);

  const trendsComponent = data.trends;
  const trends = trendsComponent?.data?.links?.data || [];

  const heros = data?.hero?.heros || [];
  if (heros.length > 0 && !heros[0]?.tagSlugs?.includes('sponso')) {
    heros[0].mainTag = t('sdk.template.homepage.featured');
  }

  const bestComments = data?.bestComments?.bestComments || [];
  const formattedBestComments =
    bestComments
      .map((currentComment: BestComment, index): any => {
        const locale = t('locale');
        const dateTimeAgo = displayDateDiff(currentComment.publishedAt, locale);

        return (
          <CommentCard
            key={index}
            pseudo={currentComment?.author?.name}
            avatar={currentComment?.author?.imageUrl}
            comment={currentComment.comment}
            createdAt={dateTimeAgo}
            link={{ href: currentComment.item.url, title: currentComment.item.title }}
            title={currentComment.item.title}
          />
        );
      })
      .filter(Boolean) || [];

  const filters = data?.filters?.filters;

  return (
    <>
      <Ad className="Billboard_1" desktopOnly megaban />
      <Container>
        <FullContent>
          <Featured>
            <Feed>
              <TitleFeed>
                <HeadingNews>
                  <HeadingTwo className="mod-huge mod-medium-black mod-without-m">
                    <NavLink href={mainFluxButton.url}>
                      {mainFluxButton?.title ? mainFluxButton.title : t('sdk.template.main_listing.headline')}
                    </NavLink>
                  </HeadingTwo>
                  {filters?.length > 0 && (
                    <Select
                      options={filters.map((filter) => {
                        return { id: filter.tagSlug, value: filter.label };
                      })}
                      onChange={(values) => {
                        setFilterTag(values[0]);
                      }}
                      multiple={false}
                      labelledBy="homepage-filter"
                      allLabel="Filtrer"
                    />
                  )}
                </HeadingNews>
                <SwitchViewMode callback={(newMode: string) => setMode(newMode)} />
              </TitleFeed>
              <PostsList>
                {genericItems?.slice(0, 20).map((genericItem: GenericItem, index: number) => {
                  const previousDate = index > 0 ? new Date(genericItems[index - 1].republishedAt) : null;
                  const currentDate = new Date(genericItem.republishedAt);
                  const label = getHistoryDate(currentDate, previousDate, t('locale'));

                  return (
                    <PostItemMix
                      key={index}
                      className={index === 0 && !label ? 'mod-first' : ''}
                      mode={mode}
                      separator={label}
                      {...postItemReducer({ item: genericItem })}
                    />
                  );
                })}
              </PostsList>
              {mainFluxButton.url && (
                <Cta href={mainFluxButton.url} className="mod-blue mod-empty mod-icon mod-sm">
                  {mainFluxButton?.button ? mainFluxButton.button : t('sdk.template.main_listing.all_news')}
                  <ArrowPlainRight width={13} height={13} color="var(--theme-palette-blue)" />
                </Cta>
              )}
            </Feed>
            <Heros>
              <TrendsElt>
                {trends &&
                  trends.length > 0 &&
                  trends
                    .filter((trend: Trend) => {
                      return trend?.url && trend.title;
                    })
                    .map((trend: Trend, index: number) => {
                      return (
                        <TrendElt key={index}>
                          <NavLink href={trend.url}>{trend.title}</NavLink>
                        </TrendElt>
                      );
                    })}
              </TrendsElt>
              <ShowcaseListing items={heros} />
              <Ad className="Mobile_Pos1" mobileOnly />
            </Heros>
          </Featured>
        </FullContent>
      </Container>
      <Container>
        <ShowcaseListingAd
          items={insert1items}
          title={insert1?.title}
          cta={{ link: insert1?.url, label: insert1?.button }}
        />
      </Container>
      {insertTab1 ? (
        <SelectedItemsLineTabs mainTitle={`${insertTab1.mainTitle}`} tabs={insertTab1.resolvedTabs} />
      ) : null}
      <FullContent>
        <AdContainer>
          <Ad className="Mobile_Pos2" mobileOnly />
          <Ad className="Billboard_2" desktopOnly />
        </AdContainer>
      </FullContent>
      <Container>
        <ShowcaseAndCompactListing
          items={insert2items}
          title={insert2?.title}
          cta={{ link: insert2?.url, label: insert2?.button }}
          spaceBottom
        />
      </Container>

      {insertTab2 ? (
        <SelectedItemsLineTabs mainTitle={`${insertTab2.mainTitle}`} tabs={insertTab2.resolvedTabs} />
      ) : null}
      <Container>
        <ShowcaseAndCompactListing
          items={insert3items}
          title={insert3?.title}
          cta={{ link: insert3?.url, label: insert3?.button }}
          spaceBottom
        />
      </Container>

      {insertTab3 ? (
        <SelectedItemsLineTabs mainTitle={`${insertTab3.mainTitle}`} tabs={insertTab3.resolvedTabs} />
      ) : null}

      <Container>
        <FullContent>
          <CommentHeading>
            <HeadingTwo className="mod-medium-black mod-huge mod-without-mt">
              {t('sdk.comments.bestComments')}
            </HeadingTwo>
          </CommentHeading>
          <CommentBody>
            <Slider items={formattedBestComments} />
          </CommentBody>
        </FullContent>
      </Container>
      <BottomNavCustom
        navigations={[
          {
            icon: <Home width={22} height={22} color="var(--title-foreground)" />,
            label: t('design.bottomNav.home'),
            type: 'href',
            href: '/',
            onClick: () => {},
          },
          {
            icon: <NewsIcon width={22} height={22} color="var(--title-foreground)" />,
            label: t('design.bottomNav.news'),
            type: 'href',
            href: '/actualites-informatique/',
            onClick: () => {},
          },
          {
            icon: <DealsIcon width={22} height={22} color="var(--title-foreground)" />,
            label: t('design.bottomNav.deals'),
            type: 'href',
            href: '/bons-plans/',
            onClick: () => {},
          },
        ]}
      />
    </>
  );
};

export default Homepage;
