import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { LinkComponent } from '../utils/component';

const BottomNavWrapper = styled.div`
  display: none;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--theme-color-background);
      border-top: 1px solid #ddd;
      justify-content: space-around;
      padding: 10px 0;
      color: var(--text-color);
      z-index: 999;

      &::after {
        content: '';
        position: fixed;
        top: -20px;
        height: 20px;
        width: 100dvh;
        background-color: red; // whatever your nav background color is
      }
    `,
  )}
`;

const NavItem = styled.div`
  flex: 1;
  text-align: center;
  font-size: 12px;
`;

const CommentNumber = styled.span`
  margin-left: -9px;
  margin-top: -20px;
  font-size: 10px;
  background: #ff0000;
  color: #ffffff;
  border-radius: 10px;
  padding: 1px 4px;
`;

const CommentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type BottomNavProps = {
  navigations?: Navigation[];
};

type Navigation = {
  icon?: any;
  type?: string;
  label?: string;
  href?: string;
  exponentLabel?: string;
  onClick?: () => void;
};

export const handleShare = async (title: string, url: string, text: string) => {
  if (navigator.share) {
    try {
      await navigator.share({
        title,
        text,
        url,
      });
    } catch (error) {
      // error
    }
  }
};

export const BottomNavCustom = ({ navigations }: BottomNavProps) => {
  return (
    <BottomNavWrapper id="bottom-nav">
      {navigations?.map((nav, index) => (
        <NavItem key={index}>
          <LinkComponent href={nav.href} onClick={nav.onClick} title={nav.label}>
            <CommentContainer>
              {nav.icon}
              {nav.exponentLabel && <CommentNumber>{nav.exponentLabel}</CommentNumber>}
            </CommentContainer>

            <span>{nav.label}</span>
          </LinkComponent>
        </NavItem>
      ))}
    </BottomNavWrapper>
  );
};
